import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaTwitter } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #000;
  position: relative;
  color: white;
`;

const Icons = styled.div`
  position: fixed;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
  z-index: 3;
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  filter: ${({ blurred }) => (blurred ? 'blur(10px)' : 'none')};
  transition: filter 0.5s ease;
`;

const Terminal = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: #0f0;
  font-family: 'Courier New', monospace;
  padding: 20px;
  width: 90%;
  height: 80vh;
  font-size: 16px;
  line-height: 1.2;
  border: 2px solid #0f0;
  box-shadow: 0 0 10px #0f0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
`;

const TerminalContent = styled.pre`
  margin: 0;
  height: 100%;
  overflow: hidden;
`;

const Prompt = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  font-style: italic;
  animation: blink 1s infinite;

  @keyframes blink {
    50% { opacity: 0; }
  }
`;

const terminalContent = `
[INCOMING TRANSMISSION - ENCRYPTED]
:::::::::::::::::::::::::::::::::::::::::::::
HYPERBOREAN_MAINFRAME v7.3.1 - CONNECT_ESTABLISHED
:::::::::::::::::::::::::::::::::::::::::::::

>>INIT_SEQUENCE_ALPHA
>>LOADING...
>>DECRYPTING...

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵

#!#!#! WARNING: REALITY_ANCHOR_UNSTABLE !#!#!#

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵

[PROTOCOL_ACTIVATION_SEQUENCE]
1. VRIL-DNA-RECURSION.exe ............ [OK]
2. LEMURIAN_AKASHIC_HACK.sys ......... [OK]
3. ANUNNAKI_BLOODLINE.dat ............ [ACTIVE]
4. NIBIRU_COUNTDOWN.timer ............ [T-MINUS 000:00:00]
5. REPTILIAN_PURGE.bat ............... [99.9% COMPLETE]
6. PINEAL_SUPERCHARGE.amp ............ [1000x OVERFLOW]
7. HYPERBOREAN_TECH_INTERFACE.dll .... [CONNECTED]
8. HOLLOW_EARTH_CORE.sample .......... [CLASSIFIED]
9. VIMANA_ANTIGRAV.iso ............... [DOWNLOADED]
10. SHAMBHALA_CLOAK.stealth .......... [ENGAGED]
11. ULTIMA_THULE_STARGATE.coords ..... [CALCULATING...]
12. SIRIAN_OVERSOUL.uplink ........... [ESTABLISHED]
13. ARCHON_FIREWALL.exe .............. [8000% CAPACITY]
14. ATLANTEAN_MATRIX_REBOOT.sys ...... [INITIALIZING]
15. TIME_CUBE_TESSERACT.4d ........... [RENDERING]
16. NEPHILIM_GENOME.dna .............. [RECONSTRUCTING]

>>ERROR<<: REALITY_BUFFER_OVERFLOW
>>ATTEMPTING_QUANTUM_RECALIBRATION...

[!] AGARTHAN_MIND-MELD_IMMINENT [!]
[!] PREPARE_FOR_CONSCIOUSNESS_UPGRADE [!]

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵

TRANSMISSION_END

∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵
`;

function App() {
  const [isBlurred, setIsBlurred] = useState(true);
  const [terminalVisible, setTerminalVisible] = useState(true);
  const [displayedContent, setDisplayedContent] = useState('');
  const videoRef = useRef(null);

  const handleContinue = () => {
    setTerminalVisible(false);
    setIsBlurred(false);
    videoRef.current.play();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && terminalVisible) {
        handleContinue();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [terminalVisible]);

  useEffect(() => {
    let i = 0;
    const typingEffect = setInterval(() => {
      if (i < terminalContent.length) {
        setDisplayedContent(prev => prev + terminalContent.charAt(i));
        i++;
      } else {
        clearInterval(typingEffect);
      }
    }, 1); // Adjust typing speed here

    return () => clearInterval(typingEffect);
  }, []);

  return (
    <Container>
      <Icons>
        <a href="https://x.com/HYPERBORIASOL" target="_blank" rel="noopener noreferrer">
          <FaTwitter size={30} color="white" />
        </a>
        <a href="https://pump.fun/" target="_blank" rel="noopener noreferrer">
          <img src="https://pump.fun/_next/image?url=%2Flogo.png&w=64&q=75" alt="Pump.fun" width="30" />
        </a>
      </Icons>
      {terminalVisible && (
        <Terminal onClick={handleContinue}>
          <TerminalContent>{displayedContent}</TerminalContent>
          <Prompt>Press Enter or click to continue...</Prompt>
        </Terminal>
      )}
      <VideoBackground
        ref={videoRef}
        blurred={isBlurred}
        loop
        src="/CVT5F762cpLU.mp4"
        playsInline
        controls={false}
      />
    </Container>
  );
}

export default App;